import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useNavbarStyles = makeStyles((theme: Theme) => ({
  tes: {
    "&.MuiContainer-root": {},
  },
  linkText: {
    textDecoration: "none",
  },
  linkActiveText: {
    "& > button.MuiButton-root": {
      color: theme.palette.secondary.main,
    },
  },
  linkButton: {
    "&.MuiButton-root": {
      fontWeight: 700,
      fontSize: "20px",
      color: theme.palette.common.black,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  },
  chartIcon: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));
