import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FC } from "react";

const CardServicii: FC<any> = (props) => {
  return (
    <Card sx={{ width: "350px", m: 2, boxShadow: "none" }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="200"
        image={props.img}
      />
      <CardContent style={{ padding: "0" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ m: 0, fontWeight: "bold" }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: "600" }}
        >
          {props.description}
        </Typography>
        <Button
          size="large"
          sx={{ p: 0, fontSize: "30px" }}
          onClick={() => props.setOpen(true)}
        >
          CERE OFERTA
        </Button>
      </CardContent>
    </Card>
  );
};

export default CardServicii;
