import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import baseTheme from "./assets/styles/baseTheme";
import { GlobalProvider } from "./store/Provider";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={baseTheme}>
      <CssBaseline />
      <GlobalProvider>
        <Router>
          <App />
        </Router>
      </GlobalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
