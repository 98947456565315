import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse/Collapse";
import { FC, useContext } from "react";
import { GlobalContext } from "../../store/Provider";

const Alerts: FC = ({ children }) => {
  const { alert } = useContext(GlobalContext);
  return (
    <>
      <Collapse in={!!alert.message}>
        <Alert severity={alert.type}>{alert.message}</Alert>
      </Collapse>
      {children}
    </>
  );
};

export default Alerts;
