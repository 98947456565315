import { FC } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const FreshProductsList: FC<any> = ({ docs, deleteById, handleEdit }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {docs && docs.length > 0 ? (
        docs.map((doc: any, i: number) => {
          const litraj = doc.litraj.split("/");
          const pret = doc.pret.split("/");
          return (
            <Card key={`${doc.name}${i}`} sx={{ width: 300, m: 2, p: 1 }}>
              <CardMedia
                component="img"
                height="200"
                image={doc.img}
                alt="Paella dish"
                sx={{
                  objectFit: "contain",
                }}
              />
              <CardContent style={{ padding: 0 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ m: 0 }}
                >
                  {doc.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {doc.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  stoc: {doc.cantitate} / discount: {doc.discount}%
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {litraj[0]}
                  {bull}
                  {litraj[1]}
                  {bull}
                  {litraj[2]}
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {pret[0]}
                  {bull}
                  {pret[1]}
                  {bull}
                  {pret[2]}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between", padding: 0 }}>
                <IconButton
                  aria-label="add to favorites"
                  onClick={() => {
                    handleEdit(doc, doc.id);
                    window.scrollTo(0, 0);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="share"
                  onClick={() => deleteById(doc.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          );
        })
      ) : (
        <Typography variant="h6" component="div">
          List is empty
        </Typography>
      )}
    </Box>
  );
};

export default FreshProductsList;
