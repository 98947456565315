import { Tabs, Tab } from "@mui/material";
import { Box } from "@mui/system";
import { FC, SyntheticEvent, useState } from "react";
import FormController from "../../components/formController/FormController";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const BackDor: FC = () => {
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ width: "100%", background: "#f3f3f3" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(e: SyntheticEvent, newValue: number) => setValue(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Cadou" />
          <Tab label="Marturie" />
          <Tab label="Fresh" />
          <Tab label="Limonada" />
          <Tab label="Smoothie" />
          <Tab label="Vitamin booster" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FormController type="cadou" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormController type="marturie" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormController type="fresh" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FormController type="limonada" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FormController type="smoothie" />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <FormController type="vitamin_booster" />
      </TabPanel>
    </Box>
  );
};

export default BackDor;
