import { FC } from "react";

interface IMailto {
  email: string;
  subject?: string;
  body?: string;
}

const Mailto: FC<IMailto> = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return (
    <a
      style={{
        color: "inherit",
        textDecoration: "none",
        display: "flex",
        alignItems: "end",
      }}
      href={`mailto:${email}${params}`}
    >
      {children}
    </a>
  );
};

export default Mailto;
