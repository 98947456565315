import { createTheme, ThemeOptions } from "@mui/material";
import { themeConstants } from "./constants";

const baseTheme: ThemeOptions = {
  palette: {
    // primary: {
    //   main: themeConstants.COLOR_PRIMARY,
    // },
    secondary: {
      main: themeConstants.COLOR_SECONDARY,
    },
  },
  typography: {
    fontSize: 10,
    htmlFontSize: 10,
    allVariants: {
      fontFamily: themeConstants.PRIMARY_FONT,
    },
  },
};

export default createTheme(baseTheme);
