import { Box, Button, Divider, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useStorage } from "../../hooks/useStorage";
import useUtils from "../../utils/useUtils";
import ProductsList from "../productList/ProductsList";

const initialState = {
  name: "",
  description: "",
  cantitate: "",
  litraj: "",
  pret: "",
  discount: "",
  img: "",
};

const FormController: FC<any> = ({ type }) => {
  const { addItem, docs, deleteById } = useStorage("store", type);
  const [hasError, setHasError] = useState<boolean>(false);
  const [id, setId] = useState("");
  const { getBase64 } = useUtils();
  const [state, setState] = useState<any>(initialState);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { name, description, cantitate, litraj, pret, discount, img } = state;
    if (
      (name &&
        description &&
        cantitate &&
        litraj &&
        pret &&
        discount &&
        img) === ""
    ) {
      setHasError(true);
    } else {
      if (id !== "") {
        addItem({ ...state, type }, id);
      } else {
        addItem({ ...state, type });
      }
      setHasError(false);
      setState(initialState);
      setId("");
    }
  };

  const handleChange = (event: any) => {
    setHasError(false);
    const { value, name } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeImg = (event: any) => {
    const { files, name } = event.target;
    const image = files[0];

    if (
      image &&
      ["image/jpeg", "image/jpg", "image/png"].includes(image.type)
    ) {
      getBase64(files[0], (reuslt) => {
        setState({
          ...state,
          [name]: reuslt,
        });
      });
    }
    setHasError(false);
  };

  const handleEdit = (data: any, id: string) => {
    setState(data);
    setId(id);
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Divider style={{ width: "100%" }}>Add/edit form</Divider>
      <TextField
        id="outlined-basic"
        variant="outlined"
        type="text"
        label="Name"
        name="name"
        onChange={handleChange}
        error={hasError}
        value={state.name}
      />
      <TextField
        id="outlined-basic"
        label="Description"
        name="description"
        onChange={handleChange}
        error={hasError}
        value={state.description}
      />
      <TextField
        id="outlined-basic"
        label="Cantitate"
        name="cantitate"
        onChange={handleChange}
        error={hasError}
        value={state.cantitate}
      />
      <TextField
        id="outlined-basic"
        label="Litraj"
        name="litraj"
        placeholder="10ml/30ml/40ml"
        onChange={handleChange}
        error={hasError}
        value={state.litraj}
      />
      <TextField
        id="outlined-basic"
        label="Pret"
        name="pret"
        placeholder="10RON/40RON/50RON"
        onChange={handleChange}
        error={hasError}
        value={state.pret}
      />
      <TextField
        id="outlined-basic"
        label="Discount"
        name="discount"
        placeholder="50%"
        onChange={handleChange}
        error={hasError}
        value={state.discount}
      />
      <Button variant="contained" component="label" sx={{ padding: "15px" }}>
        Upload File
        <input type="file" hidden onChange={handleChangeImg} name="img" />
      </Button>
      <Button type="submit" variant="contained" sx={{ padding: "15px" }}>
        Submit
      </Button>
      <Divider style={{ width: "100%" }}>Products List</Divider>
      <ProductsList
        docs={docs}
        deleteById={deleteById}
        handleEdit={handleEdit}
      />
    </Box>
  );
};

export default FormController;
