import { FC } from "react";
import img_comming_soon from "../../assets/img/realistic-coming-soon-background.jpg";
import CountdownMath from "../../components/countdownMath/CountdownMath";
import { useCadouriStyles } from "./cadouri-style";

const targetTime = new Date("2022-05-01").getTime();

const Cadouri: FC = () => {
  const { countdownMath } = useCadouriStyles();
  return (
    <div style={{ position: "relative" }}>
      <img src={img_comming_soon} width="100%" alt="coming-soon-background" />
      <div className={countdownMath}>
        <CountdownMath stopCountDownIn={targetTime} />
      </div>
    </div>
  );
};

export default Cadouri;
