import { FC } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Divider } from "@mui/material";
import Mailto from "../mailto/Mailto";
import Slider from "../slider/Slider";
import { useLocation } from "react-router-dom";
import { useFooterStyles } from "./footer-style";

const Instagram = "https://www.instagram.com/partycocktailsevents/";
const Facebook = "https://www.facebook.com/CocktailBarEvents";
const YouTube =
  "https://www.youtube.com/watch?v=ide5Yc0J0T0&ab_channel=sorinvlad";
const WhatsApp =
  "https://api.whatsapp.com/send?phone=40748955036&app=facebook&entry_point=page_cta";
const GoogleMaps =
  "https://www.google.com/maps/place/Strada+Radu+Tudoran+4,+Cluj-Napoca+400000/";

const Footer: FC = () => {
  const { footer_container, subcontainer, footer_divider, footer_description } =
    useFooterStyles();
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== "/backDoor" && (
        <Box component="footer" className={footer_container}>
          <Slider />
          <Container className={subcontainer}>
            <Box>
              <Typography
                variant="h5"
                align="left"
                fontWeight={600}
                borderBottom="2px solid #b0b435"
                width="165px"
                marginBottom="20px"
              >
                Work with us
              </Typography>
              <Box display="flex" flexWrap="wrap">
                <Box
                  paddingRight="30px"
                  sx={{
                    "& > .MuiTypography-subtitle1": {
                      fontWeight: 800,
                    },
                  }}
                >
                  <Typography variant="subtitle1" color="#fff">
                    Delivery:
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Luni-Vineri: 18:00 - 22:00
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Sambata: 10:00 - 20:00
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Duminica: 10:00 - 20:00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    "& > .MuiTypography-subtitle1": {
                      fontWeight: 800,
                    },
                  }}
                >
                  <Typography variant="subtitle1" color="#fff">
                    Evenimente:
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Luni-Vineri: Non-Stop
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Sambata: Non-Stop
                  </Typography>
                  <Typography variant="subtitle1" color="#fff">
                    Duminica: Non-Stop
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h5"
                fontWeight={600}
                borderBottom="2px solid #b0b435"
                width="165px"
                marginBottom="20px"
              >
                Follow us
              </Typography>
              <Typography
                component="div"
                color="#fff"
                display="flex"
                minWidth="200px"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  "& > svg": {
                    fontSize: "30px",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#b0b435",
                    },
                  },
                }}
              >
                <InstagramIcon
                  onClick={() => window.open(Instagram, "_blank")}
                />
                <FacebookIcon onClick={() => window.open(Facebook, "_blank")} />
                <YouTubeIcon onClick={() => window.open(YouTube, "_blank")} />
                <WhatsAppIcon onClick={() => window.open(WhatsApp, "_blank")} />
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h5"
                fontWeight={600}
                borderBottom="2px solid #b0b435"
                width="165px"
                marginBottom="20px"
              >
                Contact us
              </Typography>
              <Box
                sx={{
                  "& > .MuiTypography-subtitle1": {
                    fontWeight: 800,
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: " space-between",
                  height: "125px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  onClick={() => window.open(GoogleMaps, "_blank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#b0b435",
                    },
                  }}
                >
                  <LocationOnIcon /> Radu T, Cluj-Napoca
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#b0b435",
                    },
                  }}
                  onClick={() => window.open(`tel:0748955036`, "_blank")}
                >
                  <PhoneIphoneIcon /> 0748955036
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  sx={{
                    "&:hover": {
                      color: "#b0b435",
                    },
                  }}
                >
                  <Mailto email="contact@partycocktails.ro">
                    <EmailIcon /> contact@partycocktails.ro
                  </Mailto>
                </Typography>
                {/* <Typography
                variant="subtitle1"
                color="#fff"
                display="flex"
                alignItems="center"
                sx={{
                  "&:hover": {
                    color: "#b0b435",
                  },
                }}
              >
                <Mailto email="delivery@partycocktails.ro">
                  <EmailIcon /> delivery@partycocktails.ro
                </Mailto>
              </Typography> */}
              </Box>
            </Box>
          </Container>
          <Divider className={footer_divider}>
            <Typography className={footer_description} variant="body2">
              Copyright © 2019 PartyCocktails. All rights reserved | Design by
              PartyCocktails {new Date().getFullYear()}.
            </Typography>
          </Divider>
        </Box>
      )}
    </>
  );
};

export default Footer;
