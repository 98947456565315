import { useContext } from "react";
import { ActionsType, GlobalContext } from "../store/Provider";

const useUtils = () => {
  const { setDispatch } = useContext(GlobalContext);
  const getBase64 = (
    file: any,
    cb: (params: string | ArrayBuffer | null) => void
  ) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => cb(reader.result);
    reader.onerror = (error) =>
      setDispatch(ActionsType.SET_ALERT, {
        type: "error",
        message: error.target?.error?.message || "",
      });
  };

  return { getBase64 };
};

export default useUtils;
