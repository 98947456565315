import img_servicii from "../assets/img/block-hompage/alex-voulgaris-OG_m5CarWo4-unsplash.jpeg";
import img_cadou from "../assets/img/block-hompage/cadouri_party_cocktails.jpg";
import img_delivery from "../assets/img/block-hompage/delivery.jpg";
import nikita from "../assets/img/block-oferta-evenimente/nikita-tikhomirov-roMo1sOj8q8-unsplash.jpeg";
import igor from "../assets/img/block-oferta-evenimente/igor-stepanov-rNKKUsIJNd4-unsplash.jpeg";
import vlady from "../assets/img/block-oferta-evenimente/vlady-nykulyak-_ApfDwbmkH8-unsplash.jpeg";

export const importantLink = [
  {
    path: "/servicii",
    name: "Servicii",
    img: img_servicii,
  },
  {
    path: "/cadouri",
    name: "Cadouri",
    img: img_cadou,
  },
  {
    path: "/delivery",
    name: "Delivery",
    img: img_delivery,
  },
];

export const offerts_block = [
  {
    name: "CERE OFERTA",
    img: nikita,
    description: (
      <div>
        <h3>All Inclusive</h3>
        <p style={{ lineHeight: 1.5 }}>
          •Cocktail-uri pe perioada nelimitata <br />
          •Alcool premium <br />
          •Sucuri naturale <br />
          •Sucuri carbogazoase <br />
          •Pahare cocktail si shoturi <br />
          •Racitor Jagermeister <br />
          •Meniu 20 cocktailuri
        </p>
        <em>Incepand de la 20 RON/persoana</em>
      </div>
    ),
  },
  {
    name: "CERE OFERTA",
    img: igor,
    description: (
      <div>
        <h3>Platesti cat consum</h3>
        <p style={{ lineHeight: 1.5 }}>
          •Barmani profesionisti <br />
          •Bar mobil <br />
          •Pahare si shoturi <br />
          •Racitor Jagermeister <br />
          •Meniu cocktailuri
          <br />
          •Ne ocupam de cumparaturi la pret de distribuitor
        </p>
        <em>Incepand de la 5 RON/persoana</em>
      </div>
    ),
  },
  {
    name: "CERE OFERTA",
    img: vlady,
    description: (
      <div>
        <h3>Pachet recomandat</h3>
        <p style={{ lineHeight: 1.4 }}>
          •Barmani profesionisti
          <br />
          •Bar mobil
          <br />
          •Pahare cocktail si shoturi
          <br /> •Paie, servetel
          <br /> •Racitor Jagermeister <br /> •Siropuri+pireuri
          <br /> •Fructe+ierburi <br />
          •Meniu cocktailuri <br />
          •Ne ocupam de cumparaturi la pret de distribuitor
        </p>
        <em>Incepand de la 10 RON/persoana</em>
      </div>
    ),
  },
];

export const blogCard = [
  {
    path: "/servicii",
    name: "Servicii",
    description: "asdasdsdd",
  },
  {
    path: "/cadouri",
    name: "Cadouri",
    description: "asdasdsdd",
  },
  {
    path: "/delivery",
    name: "Delivery",
    description: "asdasdsdd",
  },
];
