import { FC } from "react";
import { useCountdownStyles } from "./countdown-style";

interface ITimeCard {
  leftTime: number;
  label: string;
}

const TimeCard: FC<ITimeCard> = ({ leftTime, label }) => {
  const { time_card_container } = useCountdownStyles();

  return (
    <span className={time_card_container}>
      <span>{leftTime}</span>
      <span>{label}</span>
    </span>
  );
};

export default TimeCard;
