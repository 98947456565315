import { string, object, array } from "yup";

const phoneNumber =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const name = /^[a-zA-Z]+$/;

export const servicesSchema = object().shape({
  name: string()
    .required("Acest camp este obligatoriu!")
    .matches(name, "Numai litere!"),
  email: string()
    .email("Email invalid!")
    .required("Acest camp este obligatoriu!"),
  telephone: string()
    .required("Acest camp este obligatoriu!")
    .matches(phoneNumber, "Numarul de telefon nu este corect!"),
  servicii: array().min(
    1,
    "Acest camp trebuie sa contina cel putin un element!"
  ),
});
