import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCadouriStyles = makeStyles((theme: Theme) => ({
  countdownMath: {
    position: "absolute",
    left: "50%",
    top: "43%",
    transform: "translate(-50%, 0)",

    [theme.breakpoints.down("lg")]: {
      top: "40%",
    },

    [theme.breakpoints.down("md")]: {
      top: "37%",
    },

    [theme.breakpoints.down("sm")]: {
      top: "23%",
    },
  },
}));
