import { Box, Typography } from "@mui/material";
import Card from "../../components/cards/Card";
import { Slider } from "../../components/carousel/Slider";
import { importantLink, offerts_block } from "../../mock";
import { useHomeStyles } from "./home-style";

const HomePage = () => {
  const {
    importantLinks,
    slider_desctiption,
    offertsBlockDescription,
    offertsBlockDescriptionOveraly,
  } = useHomeStyles();
  return (
    <>
      <Slider height={600} />
      <Box className={slider_desctiption}>
        Barmani profesionisti, entuziasmati de provocarile clientilor nostri! In
        cei 15 ani de experienta in domeniul horeca, fiecare eveniment organizat
        de noi a fost o reusita! Ne axam pe partea de cocktail bar, lemonade
        bar, coffee bar, iar la cerere, oferim suport pentru organizarea
        integrala e evenimentului, in functie de nevoia si/sau dorinta
        clientului. Impreuna cu partenerii nostri experti in decoratiuni,
        logistica, tematici creative, muzica&sound am organizat cu succes
        evenimente de care s-au bucurat de la 50 pana la 3000 de participanti.
        <br />
        Nicio provocare nu e prea mare, garantam reusita evenimentului tau!
      </Box>
      <Box className={importantLinks}>
        {importantLink.map((link) => (
          <Card
            img={link.img}
            key={link.name}
            name={link.name}
            path={link.path}
            width="400px"
          />
        ))}
      </Box>
      <Box sx={{ background: "#f4f4f4", padding: "70px 0" }}>
        <Typography variant="h3" margin="0 auto" textAlign="center">
          Oferte Evenimente
        </Typography>
        <Box className={importantLinks}>
          {offerts_block.map((item) => (
            <Card img={item.img} key={item.name} name={item.name}>
              {item.description ? (
                <>
                  <div
                    className={
                      "overlay-anchor " + offertsBlockDescriptionOveraly
                    }
                  ></div>
                  <div className={"text-anchor " + offertsBlockDescription}>
                    {item.description}
                  </div>
                </>
              ) : (
                ""
              )}
            </Card>
          ))}
        </Box>
      </Box>

      {/* <Box>
        <Box
          sx={{
            display: "flex",
            width: "30%",
            justifyContent: "space-between",
            margin: "0 auto",
            flexWrap: "wrap",
            padding: "30px 0",
          }}
        >
          <Button variant="contained">Contained</Button>
          <Button variant="contained">Contained</Button>
          <Button variant="contained">Contained</Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "85%",
            justifyContent: "space-between",
            margin: "0 auto",
            flexWrap: "wrap",
          }}
        >
          <Card key="blog1" path="/" name="Servicii" />
          <Card key="blog2" path="/" name="Cadouri" />
          <Card key="blog3" path="/" name="Delivery" />
        </Box>
      </Box> */}
      {/* <Box>
        <Box
          sx={{
            display: "flex",
            width: "30%",
            justifyContent: "space-between",
            margin: "0 auto",
            flexWrap: "wrap",
            padding: "30px 0",
          }}
        >
          <Typography variant="h3" margin="0 auto">
            Blog
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "85%",
            justifyContent: "space-between",
            margin: "0 auto",
            flexWrap: "wrap",
          }}
        >
          {blogCard.map((link) => (
            <Card
              key={link.name}
              name={link.name}
              path={link.path}
              wdth="300px"
            />
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default HomePage;
