import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/navbar/Navbar";
import BackDor from "./pages/backdor/BackDor";
import { useEffect, useState } from "react";
import SignIn from "./components/signIn/SignIn";
import { useFirebase } from "./firebase/firebase.utils";
import Servicii from "./pages/servicii/Servicii";
import Footer from "./components/footer/Footer";
import ContactModal from "./components/contact/ContactModal";
import Alerts from "./components/alerts/Alerts";
import { MissingRoute } from "./routes/MissingRoute";
import Contact from "./pages/contact/Contact";
import Cadouri from "./pages/cadouri/Cadouri";
import Delivery from "./pages/delivery/Delivery";

const App = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { auth, createUserProfileDocument } = useFirebase();
  let unsubscribeFromAuth: any = null;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        userRef?.onSnapshot((snapshot) => {
          setCurrentUser({ id: snapshot.id, ...snapshot.data() });
        });
      }
      setCurrentUser(null);
    });

    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  return (
    <>
      <ContactModal />
      <Navbar currentUser={currentUser} />
      <Alerts>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicii" element={<Servicii />} />
          <Route path="/cadouri" element={<Cadouri />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/backDoor"
            element={currentUser ? <BackDor /> : <SignIn />}
          />
          <Route path="*" element={<MissingRoute />} />
        </Routes>
      </Alerts>
      <Footer />
    </>
  );
};

export default App;
