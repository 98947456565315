import { FC, useContext } from "react";
import { Box, Typography } from "@mui/material";
import { ActionsType, GlobalContext } from "../../store/Provider";
import CardServicii from "../../components/cards/CardServicii";
import { cards } from "./cardsServicii";

const Servicii: FC = () => {
  const { setDispatch } = useContext(GlobalContext);

  return (
    <>
      <Box
        sx={{
          maxWidth: "1146px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h6"
          margin="0 auto"
          textAlign="center"
          padding="20px 0"
        >
          Stim ca timpul este important pentru dumneavoastra in realizarea
          evenimentului perfect! De aceea ne oferim sa facem cumparaturile la
          pret de distibuitor in locul dumneavoastra! Plata se face la sfarsitul
          evenimentului pe baza facturii! Eliberam si noi factura pentru
          serviciile prestate!
        </Typography>
        {cards.map((card: any, i: number) => (
          <CardServicii
            key={`${card.title}${i}`}
            {...card}
            setOpen={() => setDispatch(ActionsType.SET_CONTACT_MODAL, true)}
          />
        ))}
      </Box>
    </>
  );
};

export default Servicii;
