import { FC, forwardRef, ReactElement, Ref } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
  ) => <Slide direction="down" ref={ref} {...props} />
);
const Modal: FC<any> = (props) => {
  const { open, setOpen } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={setOpen}
      aria-describedby="alert-dialog-slide-description"
    >
      <CloseIcon
        sx={{
          position: "absolute",
          right: "10px",
          top: "7px",
          transition: "all .1s ease-in-out",
          "&:hover": {
            transform: "scale(1.5)",
          },
        }}
        onClick={setOpen}
      />
      {props.children}
    </Dialog>
  );
};

export default Modal;
