import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useSliderStyles = makeStyles((theme: Theme) => ({
  navButtons: {
    "&.MuiButtonBase-root ": {
      borderRadius: 0,
      backgroundColor: "#8bb402 !important",
      color: "#fff",
      opacity: "1 !important",
      padding: "15px",
    },
    "&:hover": {
      backgroundColor: "black !important",
    },
  },
  indicatoButtons: {
    "&.MuiButtonBase-root": {
      margin: "-50px 5px 35px",
      zIndex: 2,
      border: "3px solid white",
      borderRadius: "5px",
      padding: "10px",
      background: "transparent",
    },
  },
  activeIndicatoButtons: {
    "&.MuiButtonBase-root": {
      background: "#8bb402",
      border: "none",
      padding: "13.5px",
      "&:hover": {
        border: "3px solid white",
        borderRadius: "5px",
        padding: "10px",
        background: "#8bb402",
      },
    },
  },
}));
