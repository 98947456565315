import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { useCardStyle } from "./card-style";
import { useContext } from "react";
import { ActionsType, GlobalContext } from "../../store/Provider";

const Card = (props: any) => {
  const { cardContainer } = useCardStyle();
  const { setDispatch } = useContext(GlobalContext);

  return (
    <Paper
      elevation={5}
      className={cardContainer}
      component={props.path ? Link : "div"}
      to={props.path}
      onClick={
        props.path
          ? () => {}
          : () => setDispatch(ActionsType.SET_CONTACT_MODAL, true)
      }
    >
      <CardActionArea>
        <CardMedia
          style={{ position: "relative" }}
          component="img"
          height="350"
          image={props.img}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign="center"
          >
            {props.name}
          </Typography>
          {props.description && (
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {props.children}
    </Paper>
  );
};

export default Card;
