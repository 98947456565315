import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useFooterStyles = makeStyles((theme: Theme) => ({
  footer_container: {
    backgroundColor: "#010101",
    paddingTop: "40px",
    paddingBottom: "56px",
  },
  subcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      "& > .MuiBox-root": {
        paddingTop: "25px",
      },
    },
  },
  footer_divider: {
    marginTop: "24px",
    marginBottom: "-48px",
    color: "#fff",
    "&::before, &::after": {
      borderTop: "2px solid #b0b435",
    },
  },
  footer_description: {
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
}));
