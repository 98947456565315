import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCardStyle = makeStyles((theme: Theme) => ({
  cardContainer: {
    position: "relative",
    width: "32.5%",
    margin: "30px 0",
    textDecoration: "none",
    "&:hover": {
      "& > .MuiButtonBase-root": {
        background: "#8bb402",
        color: theme.palette.common.white,
      },
      "& > .overlay-anchor": {
        height: "82%",
      },
      "&> .text-anchor": {
        zIndex: 1,
        margin: "0 auto 0",
        position: "absolute",
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
}));
