import cocktail_bar_nunta_cluj_pret_1 from "../../assets/img/cocktail_bar_nunta_cluj_pret_1.jpg";
import cocktail_bar_nunta_cluj_pret_2 from "../../assets/img/cocktail_bar_nunta_cluj_pret_2.jpg";
import cocktail_bar_nunta_cluj_pret_3 from "../../assets/img/cocktail_bar_nunta_cluj_pret_3.jpg";
import cocktail_bar_nunta_cluj_pret_4 from "../../assets/img/cocktail_bar_nunta_cluj_pret_4.jpg";
import cocktail_bar_nunta_cluj_pret_5 from "../../assets/img/cocktail_bar_nunta_cluj_pret_5.jpg";
import cocktail_bar_nunta_cluj_pret_6 from "../../assets/img/cocktail_bar_nunta_cluj_pret_6.jpg";
import cocktail_bar_nunta_cluj_pret_7 from "../../assets/img/cocktail_bar_nunta_cluj_pret_7.jpg";
import cocktail_bar_nunta_cluj_pret_8 from "../../assets/img/cocktail_bar_nunta_cluj_pret_8.jpg";
import cocktail_bar_nunta_cluj_pret_9 from "../../assets/img/cocktail_bar_nunta_cluj_pret_9.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

import "./slider.css";
import { useState } from "react";
import Dialog from "@mui/material/Dialog/Dialog";

const Slider = () => {
  const [state, setState] = useState({
    isOpen: false,
    img: "",
  });

  const closeModal = () => setState({ isOpen: false, img: "" });

  const openModal = (img: any, isOpen = true) => setState({ isOpen, img });

  return (
    <>
      <Dialog onClose={closeModal} open={state.isOpen}>
        <img src={state.img} width="100%" height="auto" alt="modal img" />
      </Dialog>

      <Swiper
        loop={true}
        slidesPerView={5}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        freeMode={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation, Autoplay]}
        observer
        breakpoints={{
          280: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          800: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_1)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_1}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COCKTAIL BAR</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_2)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_2}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COFFEE BAR</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_3)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_3}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">WEDDING FLAIR</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_4)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_4}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">BAR MAJORAT</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_5)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_5}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COCKTAIL NUNTA</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_6)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_6}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COFFEE PRIVATE PARTY</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_7)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_7}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COKTAIL NUNTA</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_8)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_8}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">LEMONADE CORPORATE</p>
        </SwiperSlide>
        <SwiperSlide onClick={() => openModal(cocktail_bar_nunta_cluj_pret_9)}>
          <img
            src={cocktail_bar_nunta_cluj_pret_9}
            alt="cocktail_bar_img"
            width={310}
            height={200}
          />
          <div className="swiper-slide-overlay"></div>
          <p className="swiper-slide-overlay-text">COKTAIL BAR NUNTA</p>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Slider;
