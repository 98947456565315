import { useContext, useEffect, useState } from "react";
import { useFirebase } from "../firebase/firebase.utils";
import { ActionsType, GlobalContext } from "../store/Provider";

export const useStorage = (collection: string, type?: string) => {
  const { firestore, timestamp } = useFirebase();
  const { setDispatch } = useContext(GlobalContext);
  const [docs, setDocs] = useState<any>([]);

  const addItem = async (data: any, id?: string) => {
    if (id) {
      await firestore
        .collection(collection)
        .doc(id)
        .update(data)
        .then(() =>
          setDispatch(ActionsType.SET_ALERT, {
            type: "success",
            message: "Cerera ta a fost inregistrata",
          })
        )
        .catch((error) => {
          setDispatch(ActionsType.SET_ALERT, {
            type: "error",
            message: error,
          });
        })
        .finally(() =>
          setTimeout(() => {
            setDispatch(ActionsType.SET_ALERT, {
              type: "info",
              message: "",
            });
          }, 3000)
        );
    } else {
      console.log("sdasd");

      await firestore
        .collection(collection)
        .add({
          ...data,
          createdAt: timestamp,
        })
        .then(() =>
          setDispatch(ActionsType.SET_ALERT, {
            type: "success",
            message: "Cerera ta a fost inregistrata",
          })
        )
        .catch((error) => {
          setDispatch(ActionsType.SET_ALERT, {
            type: "error",
            message: error,
          });
        })
        .finally(() =>
          setTimeout(() => {
            setDispatch(ActionsType.SET_ALERT, {
              type: "info",
              message: "",
            });
          }, 3000)
        );
    }
  };
  useEffect(() => {
    const unsub = firestore
      .collection(collection)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (snap) => {
          let documents: any = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          if (type) {
            documents = documents.filter((doc: any) => doc.type === type);
          }
          setDocs(documents);
        },
        (error) =>
          setDispatch(ActionsType.SET_ALERT, {
            type: "error",
            message: error.message,
          })
      );

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, collection]);

  const deleteById = async (id: string) =>
    await firestore.collection(collection).doc(id).delete();

  return { addItem, docs, deleteById };
};
