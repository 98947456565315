import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Logo from "../../assets/img/logo1.png";
import { useNavbarStyles } from "./navbar-style";
import SideNavbar from "../sideNavbar/SideNavbar";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useFirebase } from "../../firebase/firebase.utils";
import Badge from "@mui/material/Badge/Badge";

const pages = [
  { path: "/", label: "ACASA" },
  { path: "/servicii", label: "SERVICII" },
  { path: "/cadouri", label: "CADOURI" },
  { path: "/delivery", label: "DELIVERY" },
  { path: "/contact", label: "CONTACT" },
];

const Navbar = (props: any) => {
  const { linkText, linkActiveText, linkButton, chartIcon } = useNavbarStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDrawer = () => setIsOpen(!isOpen);
  const { auth } = useFirebase();
  return (
    <>
      <SideNavbar isOpen={isOpen} toggleDrawer={toggleDrawer} pages={pages} />
      <AppBar position="sticky" sx={{ background: "#f8f9fa" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? `${linkText} ${linkActiveText}` : linkText
              }
            >
              <Typography
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  width="100px"
                  style={{ margin: "30px 0" }}
                />
              </Typography>
            </NavLink>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer}
                color="inherit"
              >
                <MenuIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <img src={Logo} alt="Logo" width="100px" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <NavLink
                  key={page.label}
                  to={page.path}
                  className={({ isActive }) =>
                    isActive ? `${linkText} ${linkActiveText}` : linkText
                  }
                >
                  <Button className={linkButton} key={page.label}>
                    {page.label}
                  </Button>
                </NavLink>
              ))}
            </Box>
            {/*CHART */}
            <Box sx={{ flexGrow: 0 }}>
              {props.currentUser && (
                <Button
                  className={linkButton}
                  sx={{ p: 0, color: "#000" }}
                  onClick={() => auth.signOut()}
                >
                  Log Out
                </Button>
              )}
              <IconButton className={chartIcon} sx={{ p: 0, color: "#000" }}>
                <Badge color="secondary" badgeContent={0}>
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
