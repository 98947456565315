import { FC } from "react";
import img_comming_soon from "../../assets/img/realistic-coming-soon-background.jpg";

const Delivery: FC = () => {
  return (
    <div>
      <img src={img_comming_soon} width="100%" alt="coming-soon-background" />
    </div>
  );
};

export default Delivery;
