import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCountdownStyles = makeStyles((theme: Theme) => ({
  time_card_container: {
    border: "2px solid",
    padding: "0 15px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",

    [theme.breakpoints.down("md")]: {
      padding: "0 10px",
      margin: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      margin: "10px",
    },

    "& > span:first-child": {
      fontSize: "50px",
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },

    "& > span:last-child": {
      fontSize: "30px",
      fontWeight: "500",
      textTransform: "uppercase",
      [theme.breakpoints.down("lg")]: {
        fontSize: "20px",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
  },
}));
