import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { useFirebase } from "../../firebase/firebase.utils";
import { ActionsType, GlobalContext } from "../../store/Provider";

const SignIn = () => {
  const { auth } = useFirebase();
  const { setDispatch } = useContext(GlobalContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const [state, setState] = useState<any>({ email: "", password: "" });

  const resetError = () => {
    setHasError(false);
    setDispatch(ActionsType.SET_ALERT, {
      type: "info",
      message: "",
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      setState({ email: "", password: "" });
      resetError();
    } catch (error: any) {
      setDispatch(ActionsType.SET_ALERT, {
        type: "error",
        message: error.message,
      });
      setHasError(true);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setState({
      ...state,
      [name]: value,
    });
    resetError();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: " 25px 10px",

        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      onSubmit={handleSubmit}
    >
      <TextField
        error={hasError}
        id="outlined-basic"
        label="Email"
        variant="outlined"
        name="email"
        type="email"
        onChange={handleChange}
        value={state.email}
        required
      />
      <TextField
        id="outlined-basic"
        label="Password"
        variant="outlined"
        name="password"
        type="password"
        value={state.password}
        onChange={handleChange}
        error={hasError}
        required
      />
      <TextField type="submit" value="Sign in" />
    </Box>
  );
};

export default SignIn;
