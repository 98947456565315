import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import Banner01 from "../../assets/img/carousel-hompage/helena-yankovska-7EbGkOm8pWM-unsplash.jpeg";
import Banner02 from "../../assets/img/carousel-hompage/kelly-sikkema-AgJdd_HgFPw-unsplash.jpeg";
import Banner03 from "../../assets/img/carousel-hompage/louis-hansel-Kix0S25vJEo-unsplash.jpeg";
import Banner04 from "../../assets/img/carousel-hompage/rinck-content-studio-EbScFvDFhx0-unsplash.jpeg";
import Banner05 from "../../assets/img/carousel-hompage/ruyan-ayten-6TwliHzUx64-unsplash.jpeg";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSliderStyles } from "./slider-style";

export const Slider = (props: any) => {
  const { navButtons, indicatoButtons, activeIndicatoButtons } =
    useSliderStyles();
  const items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      image: Banner01,
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
      image: Banner02,
    },
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      image: Banner03,
    },
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      image: Banner04,
    },
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      image: Banner05,
    },
  ];

  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      duration={250}
      navButtonsProps={{
        className: navButtons,
      }}
      indicatorIconButtonProps={{
        className: indicatoButtons,
      }}
      activeIndicatorIconButtonProps={{
        className: activeIndicatoButtons,
      }}
      animation="slide"
      autoPlay={true}
      NextIcon={<ArrowForwardIosIcon />}
      PrevIcon={<ArrowBackIosNewIcon />}
      IndicatorIcon=""
    >
      {items.map((item, i) => (
        <Paper
          key={item.name}
          sx={{
            // backgroundImage: `url(${item.image})`,
            height: props.height,
            borderRadius: 0,
          }}
        >
          <img src={item.image} alt="logo" width="100%" height="auto" />
        </Paper>
      ))}
    </Carousel>
  );
};
