import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";

interface ISideNavbar {
  isOpen: boolean;
  toggleDrawer: () => void;
  pages: { label: string; path: string }[];
}

const SideNavbar: React.FC<ISideNavbar> = ({ isOpen, toggleDrawer, pages }) => {
  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer}>
        <List>
          {pages.map((page) => (
            <ListItem button key={page.label}>
              <Button
                key={page.label}
                sx={{ my: 2, color: "#000", display: "block" }}
                component={NavLink}
                to={page.path}
              >
                {page.label}
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideNavbar;
