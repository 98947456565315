import {
  Box,
  TextField,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
  OutlinedInput,
  Chip,
  FormHelperText,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { format } from "date-fns";
import { servicesSchema } from "../validation/formValidations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStorage } from "../../hooks/useStorage";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/Provider";
// import useEmail from "../../email/useEmail";
// const { sendEmail } = useEmail();

const tip_evenimente = [
  "Nunta",
  "Petrecere corporate",
  "Botez",
  "Majorat",
  "Petrecere privata",
  "Teambuilding",
  "Aniversare",
  "Receptie",
  "Lansari de produse",
  "Inaugurari de sedii",
  "Conferinta",
  "Serbari campenesti si party-barbeque",
  "Festival",
  "Alt tip de eveniment",
];

const names = [
  "COCKTAIL BAR",
  "COFFEE BAR",
  "BEER BAR",
  "PROSECCO BAR",
  "FRESH & SMOOTHIE BAR",
  "LEMONADE BAR",
  "SHOT BAR",
  "CIGAR BAR",
  "TUICA BAR",
  "GIN BAR",
  "TIKI BAR",
];

const meniuri = [""];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  personName: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ContactForm = () => {
  const theme = useTheme();
  const [servicii, setServicii] = useState<string[]>([]);
  const [value, setDateValue] = useState<Date | null>(new Date());
  const { addItem } = useStorage("contact");
  const [event, setEvent] = useState("");
  const { isContactFormOpen, setDispatch } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    resetField,
  } = useForm({
    resolver: yupResolver(servicesSchema),
  });

  const onSubmit = (data: any) => {
    console.log(data);

    // addItem(data);
    // setDispatch(ActionsType.SET_CONTACT_MODAL, false);
    // reset();
  };

  useEffect(() => setValue("servicii", servicii));

  return (
    <Box
      component="form"
      sx={{
        p: 1,
        textAlign: "center",
        "& > :not(style)": { m: 1, width: "27ch" },
        "& > :nth-of-type(6)": { width: "35ch" },
        "& > :nth-of-type(7)": { width: "19ch" },
        "& > :nth-of-type(8), & > :nth-of-type(9)": { width: "55.6ch" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Divider
        style={{
          width: "100%",
          fontWeight: "600",
          margin: "8px 0",
          fontSize: "24px",
        }}
      >
        Contactează-ne pentru o ofertă personalizată
      </Divider>
      <TextField
        id="outlined-error-helper-text"
        label="Name*"
        helperText={errors.name?.message}
        error={!!errors.name?.message}
        {...register("name")}
      />
      <TextField
        id="outlined-basic"
        label="Email*"
        helperText={errors.email?.message}
        error={!!errors.email?.message}
        {...register("email")}
      />
      <TextField
        id="outlined-basic"
        label="Telefon*"
        helperText={errors.telephone?.message}
        error={!!errors.telephone?.message}
        {...register("telephone")}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          minDate={new Date()}
          {...register("data")}
          label="Data evenimentului"
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={(newValue: Date | null) => {
            newValue && setValue("data", format(newValue, "dd/MM/yyyy"));
            setDateValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Tip eveniment</InputLabel>
        <Select
          {...register("tip_eveniment")}
          labelId="demo-simple-select-label"
          label="Tip eveniment"
          id="demo-simple-select"
          value={event}
          onChange={(e: SelectChangeEvent) =>
            setEvent(e.target.value as string)
          }
          MenuProps={MenuProps}
        >
          {tip_evenimente.map((eveniment) => (
            <MenuItem key={eveniment} value={eveniment}>
              {eveniment}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        sx={{ width: "19ch" }}
        id="outlined-basic"
        label="Numar invitati"
        type="number"
        {...register("guests")}
      />
      {/* multiple ************************************************* */}
      <FormControl fullWidth>
        <InputLabel
          id="demo-multiple-chip-label"
          error={!!errors.servicii?.message}
        >
          Servicii*
        </InputLabel>
        <Select
          {...register("servicii")}
          labelId="demo-multiple-chip-label"
          label="Servicii*"
          id="demo-multiple-chip"
          multiple
          value={servicii}
          onChange={({
            target: { value },
          }: SelectChangeEvent<typeof servicii>) => {
            // On autofill we get a stringified value.
            const val = typeof value === "string" ? value.split(",") : value;
            setServicii(val);
            resetField("servicii");
          }}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Servicii*"
              error={!!errors.servicii?.message}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          error={!!errors.servicii?.message}
          sx={
            !!errors.servicii?.message
              ? {
                  "& > .MuiSvgIcon-root": { color: "#d32f2f" },
                }
              : {}
          }
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, servicii, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!errors.servicii?.message}>
          {errors.servicii?.message}
        </FormHelperText>
      </FormControl>
      <TextField
        id="outlined-basic"
        label="Mesajul tau"
        multiline
        rows={4}
        {...register("message")}
      />
      <Button type="submit" variant="contained" sx={{ padding: "15px" }}>
        Submit
      </Button>
    </Box>
  );
};

export default ContactForm;
