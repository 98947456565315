import cocktail_bar_img from "../../assets/img/cocktail_bar_nunta_cluj_pret_1.jpg";
import coffee_bar_img from "../../assets/img/cocktail_bar_nunta_cluj_pret_2.jpg";
import shot_bar_img from "../../assets/img/shot_bar_nunta_cluj_pret.jpg";
import wine_bar_img from "../../assets/img/wine_vin_bar_wedding_nunta_cluj_pret_somelier.jpg";
import prosecco_bar_img from "../../assets/img/prosecco_bar_wedding_nunta_cluj_pret.jpg";
import lemonade_bar_img from "../../assets/img/lemonade_limonada_bar_wedding_nunta_cluj_pret.jpg";
import fresh_bar_img from "../../assets/img/fresh_bar_nunta_cluj_pret.jpg";
import tuica_bar_img from "../../assets/img/bar_palinka_palinca_cluj_nunta_pret.jpg";
import cigar_bar_img from "../../assets/img/cigar_Whiskey_whisky_bar_wedding_nunta_cluj_pret.jpg";
import beer_bar_img from "../../assets/img/beer-image.jpg";
import gin_bar_img from "../../assets/img/gin-image.png";
import tiki_bar_img from "../../assets/img/tiki-image.jpg";

export const cards = [
  {
    img: cocktail_bar_img,
    title: "COCKTAIL BAR",
    description:
      "Cocktail bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: coffee_bar_img,
    title: "COFFEE BAR",
    description:
      "Coffee bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: beer_bar_img,
    title: "BEER BAR",
    description:
      "Beer bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: prosecco_bar_img,
    title: "PROSECCO BAR",
    description:
      "Prosecco bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: fresh_bar_img,
    title: "FRESH & SMOOTHIE BAR",
    description:
      "Fresh & Smoothie bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: lemonade_bar_img,
    title: "LEMONADE BAR",
    description:
      "Lemonade bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },

  {
    img: shot_bar_img,
    title: "SHOT BAR",
    description:
      "Shot bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: cigar_bar_img,
    title: "CIGAR BAR",
    description:
      "Cigar bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: tuica_bar_img,
    title: "TUICA BAR",
    description:
      "Tuica bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: wine_bar_img,
    title: "WINE BAR",
    description:
      "Wine bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: gin_bar_img,
    title: "GIN BAR",
    description:
      "Gin bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
  {
    img: tiki_bar_img,
    title: "TIKI BAR",
    description:
      "Tiki bar - pentru nunți, petreceri private & corporate, majorate, aniversari, recepții...",
    offer: "",
  },
];
