import { createContext, FC, useReducer } from "react";

export const ActionsType = {
  SET_ALERT: "SET_ALERT",
  SET_CONTACT_MODAL: "SET_CONTACT_MODAL",
};

interface IAlert {
  type: "error" | "info" | "success" | "warning";
  message: string;
}

interface IinitialState {
  alert: IAlert;
  setDispatch: (type: string, payload: any) => void;
  isContactFormOpen: boolean;
}

const initialState: IinitialState = {
  alert: {
    type: "info",
    message: "",
  },
  isContactFormOpen: false,
  setDispatch: () => {},
};

const AppReducer = (state: IinitialState, action: any) => {
  switch (action.type) {
    case ActionsType.SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case ActionsType.SET_CONTACT_MODAL:
      return {
        ...state,
        isContactFormOpen: action.payload,
      };
    default:
      return state;
  }
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setDispatch = (type: string, payload: any) =>
    dispatch({
      type,
      payload,
    });

  return (
    <GlobalContext.Provider
      value={{
        isContactFormOpen: state.isContactFormOpen,
        alert: state.alert,
        setDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
