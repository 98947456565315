import Modal from "../modal/Modal";
import { useContext } from "react";
import { GlobalContext, ActionsType } from "../../store/Provider";
import ContactForm from "./ContactForm";

const ContactModal = () => {
  const { isContactFormOpen, setDispatch } = useContext(GlobalContext);

  return (
    <Modal
      open={isContactFormOpen}
      setOpen={() => setDispatch(ActionsType.SET_CONTACT_MODAL, false)}
    >
      <ContactForm />
    </Modal>
  );
};

export default ContactModal;
