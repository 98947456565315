import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useHomeStyles = makeStyles((theme: Theme) => ({
  slider_desctiption: {
    position: "absolute",
    color: "#fff",
    zIndex: 1,
    left: 0,
    right: 0,
    top: "200px",
    width: "60%",
    margin: "0 auto",
    fontWeight: "500",
    fontSize: "30px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
      top: "200px",
      fontSize: "25px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "21px",
      top: "150px",
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      top: "150px",
    },
  },
  importantLinks: {
    display: "flex",
    width: "85%",
    justifyContent: "space-between",
    margin: "0 auto",
    flexWrap: "wrap",
  },

  offertsBlockDescriptionOveraly: {
    background: "lightslategray",
    opacity: "0.5",
    position: "absolute",
    top: "0",
    width: "100%",
    height: "0",
    transition: "height 0.5s",

    "&:hover": {
      height: "82%",
    },
  },

  offertsBlockDescription: {
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    margin: "-200px auto 0",
    zIndex: "-1",
    width: "350px",
    transition: "margin 0.8s",
    textAlign: "center",
  },
}));
