import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const config = {
  apiKey: "AIzaSyCeQemdLDuU2O3qtiyUk9eL30XchgSiWfQ",
  authDomain: "party-cocktails.firebaseapp.com",
  databaseURL:
    "https://party-cocktails-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "party-cocktails",
  storageBucket: "party-cocktails.appspot.com",
  messagingSenderId: "594620818813",
  appId: "1:594620818813:web:dad4f13112b85243e85814",
  measurementId: "G-84MBHQ3HBT",
};

firebase.initializeApp(config);

export const useFirebase = () => {
  const createUserProfileDocument = async (
    userAuth: any,
    additionalData?: any
  ) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();

    if (!snapShot.exists) {
      const { email } = userAuth;
      const createdAt = new Date();

      try {
        await userRef.set({
          email,
          createdAt,
          ...additionalData,
        });
      } catch (error: any) {
        console.log("error creating user", error.message);
      }
    }

    return userRef;
  };

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  return {
    createUserProfileDocument,
    auth,
    firestore,
    firebase,
    timestamp,
  };
};
