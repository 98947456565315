import { Box } from "@mui/material";
import ContactForm from "../../components/contact/ContactForm";

const Contact = () => {
  return (
    <>
      <Box component="div" style={{ maxWidth: "580px", margin: "0 auto" }}>
        <ContactForm />
      </Box>
    </>
  );
};

export default Contact;
