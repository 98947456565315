import { FC, useEffect, useState } from "react";
import TimeCard from "./TimeCard";

interface ICountdown {
  startCountDownIn?: number;
  stopCountDownIn?: number;
}

const CountdownMath: FC<ICountdown> = ({
  startCountDownIn = new Date().getTime(),
  stopCountDownIn = new Date().getTime(),
}) => {
  const [currentTime, setCurrentTime] = useState(startCountDownIn);
  const timeBetween = stopCountDownIn - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
      }}
    >
      <TimeCard leftTime={days} label={"days"} />
      <TimeCard leftTime={hours} label={"hours"} />
      <TimeCard leftTime={minutes} label={"minutes"} />
      <TimeCard leftTime={seconds} label={"seconds"} />
    </div>
  );
};

export default CountdownMath;
